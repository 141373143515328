class easybuilderMenuFrontend extends elementorModules.ViewModule {
  bindEvents() {
    $(document).ready(() => {
      var stmenu_down_timer;
      function lazyload(menu){
        $('.steasym_lazy', menu).each(function(){
          $(this).removeClass('steasym_lazy');
          if($(this).parent()[0].tagName === "PICTURE" && $(this).parent().find('source').length){
            var _source = $(this).parent().find('source');
            if(_source.length)
                _source.each(function(){
                    $(this).attr('srcset', $(this).data('srcset')).removeAttr('data-srcset');
                });
          }
          if($(this).data('src'))
            $(this).attr('src', $(this).data('src'));
          if($(this).data('srcset'))
            $(this).attr('srcset', $(this).data('srcset'));
        });
      }
      function megaHoverOver(){
          if(steasym.submemus_action==1){
              // $(".steasym_sub").stop().hide();
              $(".steasym_ml_level_0 ").removeClass("steasym_current");  
          }   
          $(this).addClass('steasym_current');
          const sub = $(this).find('.steasym_sub');
          if(sub.length){
            lazyload(sub);
            updateSlider(1, $(this));
          }
          /*if($(this).find('.steasym_sub').children().size())
          {
              var steasym_sub_dom = $(this).find(".steasym_sub");
              if(steasym.submemus_animation<=2){
                steasym_sub_dom.stop();
                stmenu_down_timer = setTimeout(function(){
                        if(steasym.submemus_animation==2){
                            steasym_sub_dom.show().css('overflow','visible');
                        }else if(steasym.submemus_animation==1){
                            steasym_sub_dom.slideDown('fast',function(){
                              steasym_sub_dom.css('overflow','visible');
                            });
                        }else{
                          steasym_sub_dom.fadeIn('fast',function(){
                            steasym_sub_dom.css('overflow','visible');
                          });
                        }
                },100);
              }
          }*/
      }
      function megaHoverOut(){ 
          // clearTimeout(stmenu_down_timer);
            updateSlider(0, $(this));
          $(this).removeClass('steasym_current');
          // $(this).find(".steasym_sub").stop().hide(); 
          // megaWatcher();
      }
      function megaWatcher(){ 
        if(!$('.steasym_ml_level_0.steasym_editing').length || $('.steasym_ml_level_0.steasym_editing.steasym_current').length)
          return false;
        if(!$('.steasym_ml_level_0.steasym_current').length){
          $('.steasym_ml_level_0.steasym_editing').addClass('steasym_current');
          $('.steasym_tab_item_editing').addClass('steasym_tab_item_current');
          $('.steasym_tab_panel_editing').addClass('steasym_tab_panel_current');
        }   
      }
      function updateSlider(kuan,dom){ 
        if(!dom.length)
          return false;
        const sliders = dom.find('.steb_pro_container');
        const slider_ids = [];
        $.each(sliders, function(){
          slider_ids.push(sliders.prop( 'id' ));
        });
        if(kuan)
          prestashop.emit('refreshStSliderShow', {ids:slider_ids});
        else
          prestashop.emit('refreshStSliderHide', {ids:slider_ids});
      }
      if(steasym.submemus_action==1){
          $(document).on('click', ".steasym_ml_level_0 .steasym_ma", function(){megaHoverOver.apply($(this).parent().get(0));return false;});
          $(document).on('click', 'html,body', function(e) {
              if($(e.target).parents('.steasym_mu_level_0').first().length==0){
                  // $(".steasym_sub").stop().hide();
                  updateSlider(0, $('.steasym_current').find('.steasym_sub'));
                  $(".steasym_ml_level_0 ").removeClass("steasym_current");  
              }
          });
      }
      else
      $(".steasym_ml_level_0 ").hoverIntent({sensitivity: 7, 
           interval: 0, 
           timeout: 0,
           over: megaHoverOver,
           out: megaHoverOut});

      if(('ontouchstart' in document.documentElement || window.navigator.msMaxTouchPoints))
      {
          $(".steasym_ml_level_0 .steasym_ma").click(function(e){
              var steasym_ml_level_0  = $(this).parent();
              if(steasym_ml_level_0 .find('.steasym_sub').children().size())
              {
                  if(!steasym_ml_level_0 .hasClass('ma_touched'))
                  {
                      $(".steasym_ml_level_0 ").removeClass('ma_touched');
                      steasym_ml_level_0 .addClass('ma_touched');
                      return false;
                  }
                  else
                      steasym_ml_level_0 .removeClass('ma_touched');
              }
          });
          $('.steasym_sub .has_children').click(function(e){
              if(!$(this).hasClass('item_touched'))
              {
                  $(".steasym_sub .menu_touched").removeClass('item_touched');
                  $(this).addClass('item_touched');
                  return false;
              }
              else
                  $(this).removeClass('item_touched');
          });
      }
      $(document).on('mouseenter', '.steasym_tab_item', function(){
        updateSlider(0, $(this).siblings('.steasym_tab_item_current').closest('.steasym_tab_con').find('.steasym_tab_panel'));
        $(this).siblings().removeClass('steasym_tab_item_current').end().addClass('steasym_tab_item_current').closest('.steasym_tab_con').find('.steasym_tab_panel').removeClass('steasym_tab_panel_current');
        $('.steasym_tab_panel_'+$(this).data('target')).addClass('steasym_tab_panel_current');
        updateSlider(1, $('.steasym_tab_panel_'+$(this).data('target')));
      }).on('mouseleave', '.steasym_tab_con', function(){
        // $(this).find('.steasym_tab_item_current').removeClass('steasym_tab_item_current').end().find('.steasym_tab_panel_current').removeClass('steasym_tab_panel_current');
      }).on('mouseenter', '.steasym_ul', function(){
        if(steasym.submemus_action==1) // click de shi hou bu zhi dong da kai
          $(this).removeClass('steasym_ul_active_0 steasym_ul_active_1');
        else
          $(this).removeClass('steasym_ul_active_0').addClass('steasym_ul_active_1');
      }).on('mouseleave', '.steasym_ul', function(){
        if(steasym.submemus_action==1)
          $(this).removeClass('steasym_ul_active_0 steasym_ul_active_1');
        else
          $(this).removeClass('steasym_ul_active_1').addClass('steasym_ul_active_0');
      }).on('mouseenter', '.steasym_dropdown_tab_has_width_0', function(){
        const container = $(this).closest('.steasym_main_container');
        $(this).find('.steasym_dropdown_tab_panel').width($('.steasym_main_container').width()-($(this).offset().left - container.offset().left)-$(this).width());
      }).on('mouseenter', '.steasym_dropdown_tab_item', function(){
        $(this).addClass('steasym_dropdown_tab_current');
        updateSlider(1, $(this));
      }).on('mouseleave', '.steasym_dropdown_tab_item', function(){
        updateSlider(0, $(this));
        $(this).removeClass('steasym_dropdown_tab_current');
      });

      prestashop.on('stSwitchMenuEvent', (event) => {
        $(".steasym_ml_level_0").off();
        $(document).off('click', ".steasym_ml_level_0 .steasym_ma");
        if(event.value=='1'){
            $(document).on('click', ".steasym_ml_level_0 .steasym_ma", function(){megaHoverOver.apply($(this).parent().get(0));return false;});
            $(document).on('click', 'html,body', function(e) {
                if($(e.target).parents('.steasym_mu_level_0').first().length==0){
                    $(".steasym_sub").stop().hide();
                    $(".steasym_ml_level_0").removeClass("current");           
                }
            });
        }
        else
            $(".steasym_ml_level_0").hoverIntent({sensitivity: 7, 
                 interval: 0, 
                 timeout: 0,
                 over: megaHoverOver,
                 out: megaHoverOut});
      });
      $(document).on('click', '.steasym_mma_wrap .steasym_opener', function(){
        const mml = $(this).closest('.steasym_mml').toggleClass('steasym_kai');

        lazyload(mml); // you mei you bi yao zhi zai di yi ji zhi xin

        if(steasym.oneopen && mml.siblings('.steasym_kai').length)
          mml.siblings('.steasym_kai').removeClass('steasym_kai');
      });
    });  
  }
}

window.easybuilderMenuFrontend = new easybuilderMenuFrontend();